
import sabru from '../../../assets/images/sabru.png'
export const tarjetas = [
    {
      imagen: sabru,
      nombre: '@sabru_alvarez',
      texto: '"¡Nunca había sentido tanta adrenalina participando en una obra de teatro! Nos sumergimos en la trama y fué increíble."',
    },
    {
      imagen: sabru,
      nombre: '@sabru_alvarez',
      texto: '"¡Nunca había sentido tanta adrenalina participando en una obra de teatro! Nos sumergimos en la trama y fué increíble."',
    },
    {
        imagen: sabru,
        nombre: '@sabru_alvarez',
        texto: '"¡Nunca había sentido tanta adrenalina participando en una obra de teatro! Nos sumergimos en la trama y fué increíble."',
      },
   
  ];